import React from "react";

import Page from "../../components/Page";
import BannerParallax from "../../components/BannerParallax";

import imgBackground from "../../images/banner/banner-4-concert-performance.jpg";
import ConcertPerformances from "./ConcertPerformances";

export default () => {
  const pageTitle = "Concerts & Performances";
  return (
    <Page title={pageTitle}>
      <BannerParallax title={pageTitle} background={imgBackground} />
      <ConcertPerformances />
    </Page>
  );
};
